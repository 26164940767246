/* src/index.css */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: 'Roboto Mono', monospace;
  background-color: #0d1117;
  color: #c9d1d9;
}

input::placeholder,
textarea::placeholder {
  color: #6e7681;
}

button {
  outline: none;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #21262d;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #30363d;
}
